import { useAnimation } from "@angular/animations";
import { AppComponent } from "../app/app.component";

import { AppConfig } from "../app/app.config"

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  production: true,
  brandName: "Groupbenefitz",
  app: {

    grp: "https://testapi.groupbenefitz.aitestpro.com/",
       
    // grp:"https://api-dev.groupbenefitz.ca/",

    // grp:"https://api.groupbenefitz.ca/",
      
    personalInfoCaptcha:"6Lfj88QgAAAAAAJ8bY1NW0Bgmm9V8PV2onC4RCNx",
    fusbillrecaptcha:"6LfVtGwUAAAAALHn9Ycaig9801f6lrPmouzuKF11",

    localgrp:"http://localhost:4200/",
    atp: "https://dev.aitestpro.com/",

    formLink:"", //IHNV6Nv60377 //testausus

    id: "fooClientId",
    key: "secret",
  },


  googleMapKey: "AIzaSyCx_ZaqWDu6leZ7ffeIz5sG9qrN5s4KFF0",
  debugEnabled: true,
};
